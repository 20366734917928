<template>
  <router-view />
</template>
<script>

export default {
  name: 'App',
  components: {
  },
  // mounted() {
  //   this.$cable.connection.disconnect();
  //   this.$cable.connection.connect();
  // },
}
</script>

<style>
@import '@/assets/css/base.scss';
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
</style>
