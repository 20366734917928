import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  { path: '/',
    redirect: '/repos'
  },
  {
    path: '/test-runs/:test_run_id',
    name: "test-runs",
    component: ()=> import("./views/PageTestResult")
  },
  {
    path: '/repos',
    name: "repos",
    component: ()=> import("./views/PageRepos")
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes: routes
})

export default router