import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import antDesign from "ant-design-vue"
import 'ant-design-vue/dist/antd.css';

import ActionCableVue from "actioncable-vue";
import { createPinia } from 'pinia';
import VueSelect from "vue-select";

import '@/assets/scss/app.scss';
import "vue-select/dist/vue-select.css";

const actionCableVueOptions = {
  debug: true,
  debugLevel: "error",
  connectionUrl: "ws://localhost:3000/cable",
  connectImmediately: true,
};

const pinia = createPinia()

const app  = createApp(App)
app.use(router)
app.use(antDesign)
app.use(pinia)
app.component("v-select", VueSelect)
app.use(ActionCableVue, actionCableVueOptions);
app.mount('#app')

